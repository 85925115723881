import { useCallback, useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import { Outlet, useNavigate } from 'react-router-dom';

import moment from 'moment';

import { useAppDispatch } from '../../app/redux/hooks';
import LoadingModal from '../../common/components/loading-modal/LoadingModal';
import logger from '../../common/logger/AppLogger';
import { appSubscriptionService } from '../../common/subscriptions/appSubscriptionService';
import {
  useCancelCardPaymentMutation,
  useGetTenderMediasQuery,
  useLazyGetTransactionForOperatorQuery,
  useOperatorSignOffMutation,
} from '../api';
import { clearOperatorSession, useIsTrainingMode, useOperatorSession, useWorkstation } from '../appState';
import { useFeatureFlags } from '../config';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { clearOrderState } from '../order/orderSlice';

import TenderMediaPanel from './TenderMediaPanel';
import TenderOrderPanel from './TenderOrderPanel';
import { setTenderMedias, setTransaction, useActiveTransactionId, useTransaction } from './tenderSlice';

function Tender() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const workstation = useWorkstation();
  const operatorSession = useOperatorSession();
  const transaction = useTransaction();
  const [cardPaymentNotification, setCardPaymentNotification] = useState<string>('');
  const activeTransactionId = useActiveTransactionId();
  const isTrainingMode = useIsTrainingMode();

  const activeTransaction = transaction.find((x) => x.id == activeTransactionId);

  const isCardPaymentInProgress = transaction.some((p) => p.cardPaymentInProgress === true);

  const featureFlags = useFeatureFlags();
  const [showCancelCard, setShowCancelCard] = useState(false);
  const [showConfirmCancelCard, setShowConfirmCancelCard] = useState(false);
  const [cancelError, setCancelError] = useState('');
  const [cancelRequested, setCancelRequested] = useState(false);

  const keyboardState = useRef('');
  const [triggerSignOff] = useOperatorSignOffMutation();
  const { data: tenderMediaData } = useGetTenderMediasQuery();
  const [triggerGetTransactionForOperator, { data: transactionForOperator }] = useLazyGetTransactionForOperatorQuery();
  const [triggerCardCancel] = useCancelCardPaymentMutation();

  const runCancelCard = () => {
    setCancelError('');
    setShowConfirmCancelCard(false);
    setCancelRequested(true);
    if (operatorSession && activeTransaction) {
      triggerCardCancel({
        operatorId: operatorSession.operator.id,
        transactionId: activeTransaction.id,
      })
        .unwrap()
        .then(() => {
          logger.debug('Card Cancel Requested');
        })
        .catch((err) => {
          setCancelRequested(false);
          if (err.status === 402) {
            if (err.data) {
              const result = err.data;
              const priceFormatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
              });
              dispatch(
                showErrorDialog({
                  message: `Card payment failure for ${result.transactionNumber}/${result.sequenceNumber}.<br><br> Operator was <b>${
                    result.operatorName
                  }</b><br>Value <b>${priceFormatter.format(result.amount)}</b>`,
                  dismissible: true,
                  buttonText: 'Payment Failed',
                  backgroundColor: 'red',
                  alternateButtonText: 'Payment Completed',
                  showAlternateButton: true,
                  confirmationText: 'Please confirm this payment did NOT succeed. You will need to resolve the issue.',
                  confirmationTextAlternate:
                    'Please confirm the payment succeeded, you will need to enter the Auth Code off the card machine.',
                  state: {
                    transactionId: result.transactionId,
                    transactionNumber: result.transactionNumber,
                    sequenceNumber: result.sequenceNumber,
                    operatorName: result.operatorName,
                    amount: result.amount,
                    message: `Card payment failure for ${result.transactionNumber}/${result.sequenceNumber}.<br><br> Operator was <b>${
                      result.operatorName
                    }</b><br>Value <b>${priceFormatter.format(result.amount)}</b>`,
                    type: 'card-failure',
                  },
                  alternateState: {
                    transactionId: result.transactionId,
                    transactionNumber: result.transactionNumber,
                    sequenceNumber: result.sequenceNumber,
                    operatorName: result.operatorName,
                    amount: result.amount,
                    reason: '',
                    cardRequestId: result.cardRequestId,
                    type: 'offline-card',
                    message: `Card payment failure for ${result.transactionNumber}/${result.sequenceNumber}.<br><br> Operator was <b>${
                      result.operatorName
                    }</b><br>Value <b>${priceFormatter.format(result.amount)}</b>`,
                  },
                }),
              );
            }
            logger.warn('Card Cancel cleared, need to refresh', err);
          } else if (err.status === 417) {
            setCancelError('DOJO is just waiting to confirm the payment has not gone through. Please wait a few moments and try again.');
            // We have a timeout loop running, so we can ignore this
            logger.warn('Card Cancel cleared, need to refresh', err);
          } else logger.error('Card Cancel Error', err);
        });
    }
  };

  useEffect(() => {
    const sub = appSubscriptionService.cardNotificationObservable().subscribe((val) => {
      if (activeTransactionId && activeTransactionId === val.transactionId) setCardPaymentNotification(val.notification);
    });
    return () => sub.unsubscribe();
  }, [activeTransactionId]);

  useEffect(() => {
    const tx = transaction.find((x) => x.id == activeTransactionId);
    if (tx?.cardPaymentInProgress) {
      const waitSeconds = 120;
      console.debug('tx', tx.payAtCounterStartTime);
      const pacTimeStarted = moment(tx.payAtCounterStartTime).valueOf();
      console.debug('t2', pacTimeStarted);
      const timeNow = moment().valueOf();
      console.debug('t3', timeNow);
      console.debug(timeNow - pacTimeStarted);
      const timeToWait = timeNow - pacTimeStarted > waitSeconds * 1000 ? 0 : waitSeconds * 1000 - (timeNow - pacTimeStarted);
      console.warn('CARD PAYMENT CANCEL TIMER', timeToWait);
      const timeout = setTimeout(() => {
        console.warn('CANCEL CARD SHOW');
        setShowCancelCard(true);
      }, timeToWait);
      return () => clearTimeout(timeout);
    }
  }, [transaction, activeTransactionId]);

  const signOff = useCallback(() => {
    if (operatorSession !== undefined) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch(() => {
          clearOperatorSession();
          navigate('/');
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);

  useEffect(() => {
    const sub = appSubscriptionService.cardPaymentSuccessObservable().subscribe((cardNotification) => {
      if (operatorSession == undefined) navigate('/');
      else {
        if (cardNotification.transactionId === activeTransactionId) {
          setShowCancelCard(false);
          setCancelRequested(false);
          setShowConfirmCancelCard(false);
          triggerGetTransactionForOperator(operatorSession.operator.id)
            .unwrap()
            .then((data) => {
              if (data.length > 0) {
                const activeTransaction = data.find((x) => x.id == activeTransactionId);
                logger.warn('Magic data2', activeTransaction);
                if (activeTransaction?.totalDue === 0) navigate(`/tender/summary/${activeTransactionId}`);
              }
            })
            .catch((err) => {
              if (err.status && err.status === 404) navigate(`/tender/summary/${activeTransactionId}`);
            });
        }
      }
    });
    return () => sub.unsubscribe();
  }, [activeTransactionId, navigate, operatorSession, triggerGetTransactionForOperator]);

  useEffect(() => {
    const sub = appSubscriptionService.cardPaymentFailureObservable().subscribe((failedTransactionId) => {
      if (operatorSession == undefined) navigate('/');
      else {
        if (failedTransactionId === activeTransactionId) {
          setShowCancelCard(false);
          setCancelRequested(false);
          setShowConfirmCancelCard(false);
          triggerGetTransactionForOperator(operatorSession.operator.id)
            .unwrap()
            .then((data) => {
              if (data.length > 0) {
                const activeTransaction = data.find((x) => x.id == activeTransactionId);
                logger.warn('Magic data3', activeTransaction);
                if (activeTransaction?.totalDue === 0) navigate(`/tender/summary/${activeTransactionId}`);
              }
            })
            .catch((err) => {
              if (err.status && err.status === 404) navigate(`/tender/summary/${activeTransactionId}`);
            });
        }
      }
    });
    return () => sub.unsubscribe();
  }, [activeTransactionId, navigate, operatorSession, triggerGetTransactionForOperator]);

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOff = /^0{3,4}(00)$/gm;
        if (operatorSession === undefined) navigate('/');
        else {
          const regexSignOffMatch = keyboardState.current.match(regexSignOff);
          if (regexSignOffMatch !== null) {
            logger.info('Sign Off needed');
            signOff();
          }
        }

        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [operatorSession, signOff, dispatch, navigate]);

  useEffect(() => {
    if (tenderMediaData) dispatch(setTenderMedias(tenderMediaData));
  }, [tenderMediaData, dispatch]);

  useEffect(() => {
    if (isCardPaymentInProgress == false) setShowCancelCard(false);
  });

  useEffect(() => {
    if (operatorSession === undefined) navigate('/');
    else triggerGetTransactionForOperator(operatorSession.operator.id);
  }, [operatorSession, navigate, triggerGetTransactionForOperator]);
  useEffect(() => {
    if (transactionForOperator !== undefined) {
      dispatch(setTransaction(transactionForOperator));
      if (transactionForOperator.length > 1) navigate('../tender');
    }
  }, [transactionForOperator, dispatch, navigate]);
  if (tenderMediaData === undefined || transaction.length === 0) return <LoadingModal />;

  if (activeTransaction === undefined) return <LoadingModal />;

  return (
    <>
      {isTrainingMode && <div className="training-mode-banner">TRAINING MODE</div>}
      <div className="pos-container pos-container_role_transaction">
        <div className="pos-transaction">
          <TenderOrderPanel />
          <TenderMediaPanel />
        </div>
      </div>
      <Outlet />
      {showConfirmCancelCard === false && transaction.length > 0 && isCardPaymentInProgress && (
        <div className="pos-modal-portal">
          <div className="pos-modal-overlay">
            <div className="pos-loader pos-loader_type_light">
              <div className="pos-loader__group">
                <div className="pos-loader__spinner"></div>
                <div className="pos-loader__message">
                  <p className="pos-loader__message-text" style={{ maxWidth: '95vw' }}>
                    <span className="pos-loader__message-text-line">
                      Card {activeTransaction.isRefund ? 'Refund' : 'Payment'} in progress on{' '}
                    </span>
                    <span className="pos-loader__message-text-line">
                      <span className="pos-loader__message-text-bold">{activeTransaction.pinEntryDeviceTerminalId}</span>
                    </span>
                    <span className="pos-loader__message-text-line">
                      <span className="pos-loader__message-text-bold">
                        for {activeTransaction.transactionNumber}/{activeTransaction.sequenceNumber}
                      </span>
                    </span>
                    <span className="pos-loader__message-text-line">
                      <span className="pos-loader__message-text-bold">{cardPaymentNotification}</span>
                    </span>
                    {cancelError !== '' && (
                      <span className="pos-loader__message-text-line">
                        <span className="pos-loader__message-text-bold" style={{ color: '#ff0000' }}>
                          {cancelError}
                        </span>
                      </span>
                    )}
                    {showCancelCard && featureFlags?.hideEftCancel !== true && (
                      <span className="pos-loader__message-text-line">
                        {cancelRequested === false && (
                          <button
                            type="button"
                            className="pos-transaction__action pos-transaction__action_role_split"
                            onClick={() => setShowConfirmCancelCard(true)}
                          >
                            REQUEST CANCEL
                          </button>
                        )}
                        {cancelRequested && (
                          <button type="button" className="pos-transaction__action pos-transaction__action_role_split">
                            CANCEL REQUESTED
                          </button>
                        )}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showConfirmCancelCard === true && (
        <div className="pos-modal-portal">
          <div className={`pos-modal-overlay`}>
            <div className="pos-popup pos-popup_role_error">
              <div className="pos-popup__content">
                <div className="pos-popup__icon"></div>
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">Please Confirm!</h3>
                  <p className="pos-popup__text">Are you sure you?</p>
                </div>
                <div className="pos-popup__actions">
                  <button className="pos-popup__action" onClick={runCancelCard}>
                    Yes
                  </button>
                  <button className="pos-popup__action" onClick={() => setShowConfirmCancelCard(false)}>
                    No
                  </button>
                </div>
                <div className="pos-popup__info">
                  <p className="pos-popup__text" style={{ marginTop: '20px', marginBottom: '0', fontSize: '14px', minHeight: 'unset' }}>
                    Till {workstation?.terminalNumber} at <Moment format="DD/MM/yyyy HH:mm:ss" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Tender;
